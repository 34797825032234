import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import BaseLayout from "layouts/sections/components/BaseLayout";
import View from "layouts/sections/components/View";
import axiosClient from "app/axiosClient";

function NewsDetailPage() {
  const { id } = useParams(); // Получаем id из URL
  const [newsItem, setNewsItem] = useState(null);

  useEffect(() => {
    async function fetchNewsDetail() {
      try {
        const response = await axiosClient.get(`/api/news/${id}`);
        setNewsItem(response.data);
      } catch (error) {
        console.error("Ошибка при получении новости:", error);
      }
    }

    fetchNewsDetail();
  }, [id]);

  if (!newsItem) {
    return <div>Загрузка...</div>;
  }

  const { image, name, content, description } = newsItem;

  return (
    <BaseLayout
      title={name}
      breadcrumb={[
        { label: "Главная", route: "/" },
        { label: "Новости", route: "/news" },
      ]}
    >
      <View title="" height="40rem">
        <MKBox component="section" my={6} py={6}>
          <MKTypography variant="h2" fontWeight="bold">
            {name}
          </MKTypography>
          <MKTypography variant="body1" mt={2}>
            {content}
          </MKTypography>
          <MKTypography variant="body2" mt={2} color="secondary">
            {description}
          </MKTypography>
          <img src={image} alt={name} style={{ width: "100%" }} />
        </MKBox>
      </View>
    </BaseLayout>
  );
}

export default NewsDetailPage;
