import React, {useEffect, useState, useCallback} from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import UploadForm from "./UploadForm";
import {isUserAuthenticated} from "common/auth";
import Carousel, {Modal, ModalGateway} from "react-images";


const isAuthenticated = isUserAuthenticated();

const Gallery = () => {
  const [photos, setPhotos] = useState([]);

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const fetchPhotos = async () => {
    try {
      const response = await axios.get("https://mayakbishkek.kg/api/getAllPhotos");
      console.log(response.data)
      setPhotos(response.data);
    } catch (error) {
      console.error("Ошибка при загрузке фотографий:", error);
    }
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  const handleDeletePhoto = async (photoId) => {

    try {
      console.log("Deleting photo with ID:", photoId); // Добавьте эту строку
      await axios.delete(`/api/deletePhoto/${photoId}`);
      fetchPhotos();
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  return (
    <MKBox component="section" py={12} my={2}>
      <div>{isAuthenticated && <UploadForm onUploadSuccess={fetchPhotos}/>}</div>
      <Container>
        <div>
          <h1>Галерея</h1>
        </div>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{mx: "auto"}}>
          <Grid item xs={12} lg={20} sx={{ml: "auto"}}>

            <MKBox
              component={Grid}
              container
              spacing={3}
              sx={{
                mt: {xs: 0, md: 3},
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                "@media (maxWidth: 600px)": {
                  flexDirection: "column",
                },
              }}
            >
              {photos.map((photo, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={photo.id}
                  style={{
                    flex: "0 0 50%",
                  }}
                >
                  <div style={{position: "relative"}}>
                    <img
                      src={`https://mayakbishkek.kg/api/photos/${photo.id}`}
                      alt="Загруженное фото"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => openLightbox(index)}
                    />

                    {isAuthenticated && (
                      <button
                        onClick={() => handleDeletePhoto(photo.id)}
                        style={{
                          position: "absolute",
                          top: "0.5rem",
                          right: "0.5rem",
                          background: "none",
                          border: "none",
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Удалить
                      </button>
                    )}

                  </div>
                </Grid>
              ))}
            </MKBox>
          </Grid>
        </Grid>
      </Container>

      {/* Модальное окно для полноэкранного просмотра фотографии */}

      <div>
        {/*<RPGallery photos={photos} onClick={openLightbox}/>*/}
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((photo) => ({
                  // ...x,
                  src: `https://mayakbishkek.kg/api/photos/${photo.id}`,
                  srcset: photo.srcSet,
                  // caption: photo.title
                  caption: photo.fileName
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>

    </MKBox>
  );
};

export default Gallery;
