import React from "react";
import NewsSection from "../home/sections/NewsSection"; // Теперь правильный импорт
import UploadNewsForm from "./UploadNewsForm"; // Create this component for uploading news
import MKBox from "components/MKBox";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";

// Routes
import routes from "common/routes";
import footerRoutes from "common/footer-routes";
import { isUserAuthenticated } from "common/auth";

const NewsPage = () => {
  const isAuthenticated = isUserAuthenticated(); // Assuming this is available globally
  console.log('Stored accessToken:', localStorage.getItem("accessToken"));

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" py={6} px={2} mt={3}>
        {isAuthenticated && <UploadNewsForm />}
        <NewsSection />
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default NewsPage;
