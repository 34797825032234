import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ExampleCard from "../components/ExampleCard";
import axiosClient from "app/axiosClient";
import FilterForm from "../../FilterForm";
import CustomPagination from "components/Pagination/CustomPagination";
import { BASE_URL } from "app/api";
import axios from "axios";

function NewsSection() {
  const [news, setNews] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage] = useState(6);
  const [filterValues, setFilterValues] = useState({ title: "", description: "", category: "" });
  const [categories, setCategories] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch categories for the dropdown
  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await axiosClient.get('/api/common-reference/by-type/002');
        const categoriesData = response.data.data.map(category => ({
          value: category.id, label: category.name
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }

    fetchCategories();
  }, []);

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
    }
  };

  // Fetch news data whenever filter values or page number change
  useEffect(() => {
    async function getNewsData() {
      try {
        const response = await axios.get('/api/news/by-filters', {
          params: {
            description: filterValues.description,
            title: filterValues.title,
            category: filterValues.category,
            page: page,
            size: itemsPerPage,
            sort: "asc", 
          }
        });

        setTotalPages(response.data.data.totalPages);

        const newsData = response.data.data.content.map(newsItem => ({
          image: `${BASE_URL}/api/attachments/download/news/${newsItem.id}`,
          name: newsItem.title,
          route: `/news/${newsItem.id}`,
          content: newsItem.content,
          description: newsItem.description,
        }));

        setNews(newsData);  // Set the news data for the current page
        setFilteredNews(newsData);  // Update filtered news

      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    }

    getNewsData();
  }, [filterValues, page, itemsPerPage]);

  if (!news || news.length === 0) {
    return (
      <MKBox component="section" my={6} py={6}>
        <Container>
          <MKTypography variant="h2" fontWeight="bold">
            Загрузка новостей...
          </MKTypography>
        </Container>
      </MKBox>
    );
  }

  const renderData = news.map(({ image, name, content, route, description }) => (
    <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
      <Link
        to={{
          pathname: route,
          state: { image, name, content, description }
        }}
        style={{
          display: "block",
          width: "100%",
          height: "100%",
        }}
      >
        <ExampleCard image={image} name={name} content={content} />
      </Link>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            Новости
          </MKTypography>
        </Grid>
      </Container>
      <CustomPagination
        currentPage={page}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <Container sx={{ mt: 6 }}>
        <Grid container spacing={3}>{renderData}</Grid>
      </Container>
    </MKBox>
  );
}

export default NewsSection;
