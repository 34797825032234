import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ExampleCard from "../components/ExampleCard";
import axios from "axios";
import { BASE_URL } from "app/api";

function NewsSectionHome() {
  const [news, setNews] = useState([]);



  useEffect(() => {
    async function getNewsData() {
      try {
        const response = await axios.get(`${BASE_URL}/api/news/latest`, {

        });
  
        
          const newsData = [
            {
              title: "Последние новости Кыргызстана",
              description: "В Кыргызстане предпринимаются значительные усилия для борьбы с различными видами зависимостей.",
              items: response.data.map(newsItem => ({
                image: `${BASE_URL}/api/attachments/download/news/${newsItem.id}`,
                name: newsItem.title,
                route: `/news/${newsItem.id}`,
                content: newsItem.content, 
                description: newsItem.description, 
              })),
            }
          ];
          console.log(newsData);
          setNews(newsData);  
    
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    }
  
    getNewsData();
  }, []);
  


  if (!news || news.length === 0) {
    return (
      <MKBox component="section" my={6} py={6}>
        <Container>
          <MKTypography variant="h2" fontWeight="bold">
            Загрузка новостей...
          </MKTypography>
        </Container>
      </MKBox>
    );
  }
  const renderData = news.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>

      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {items.map(({ image, name, content, route, description }) => (
            <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
              <Link 
                to={{
                  pathname: route, 
                  state: { image, name, content, description }
                }} 
                style={{
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <ExampleCard image={image} name={name} content={content} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            Новости
          </MKTypography>
        </Grid>  
      </Container>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default NewsSectionHome;
