import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import axiosClient from "app/axiosClient";
import { Box, Button, Typography, Card, Grid, TextField, CircularProgress } from "@mui/material";

const UploadNewsForm = ({ onUploadSuccess }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [userId, setUserId] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false); 

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.userId);
      } catch (error) {
        console.error("Ошибка декодирования токена:", error);
      }
    }
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : ""); 
  };

  const uploadPhoto = async (newsId) => {
    if (!file) return;
    setIsUploading(true);

    const formData = new FormData();
    const attachmentDto = {
      type: "NEWS_IMAGE",
      originName: file.name,
      description: "News related image",
      newsId: newsId,
    };

    formData.append("dto", JSON.stringify(attachmentDto));
    formData.append("file", file);

    try {
      await axiosClient.post("/api/attachments/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setFile(null); 
      setFileName("");
    } catch (error) {
 } finally {
      setIsUploading(false); 
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosClient.post("/api/news", { 
        title, 
        description, 
        content, 
        userId,     
         isPublished: true,
        isArchived: false});
      const newsId = response.data?.id;
      if (newsId) {
        await uploadPhoto(newsId);
        setTitle("");
        setDescription("");
        setContent("");
        if (onUploadSuccess) onUploadSuccess();
      }
    } catch (error) {
      console.error("Ошибка при добавлении новости:", error);
    }
  };

  return (
    <Box px={3} py={5}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8} lg={6}>
          <Card elevation={3} sx={{ p: 3 }}>
            <Typography variant="h4" mb={3} align="center">
              Добавить Новость
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Заголовок"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                margin="normal"
              />
              <TextField
                label="Описание"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                multiline
                rows={3}
                margin="normal"
              />
              <TextField
                label="Контент"
                fullWidth
                value={content}
                onChange={(e) => setContent(e.target.value)}
                required
                multiline
                rows={5}
                margin="normal"
              />
              <Box mb={2} textAlign="center">
                <label htmlFor="file-input">
                  <input
                    id="file-input"
                    type="file"
                    name="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <Button color="primary" variant="contained" component="span">
                    Выбрать фотографию
                  </Button>
                </label>
                {fileName && <Typography mt={1}>Выбранный файл: {fileName}</Typography>}
              </Box>
              {isUploading && (
                <Box mb={2} textAlign="center">
                  <CircularProgress size={24} />
                  <Typography variant="body2" mt={1}>Загрузка фотографии...</Typography>
                </Box>
              )}
              <Button type="submit" variant="contained" color="primary" fullWidth disabled={isUploading}>
                {isUploading ? "Добавление..." : "Добавить новость"}
              </Button>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadNewsForm;
