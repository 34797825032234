

import InformationIcon from "assets/images/navbar/Информация.png";
import GalleryIcon from "assets/images/navbar/Галерея.png";
import AboutUsIcon from "assets/images/navbar/О нас.png";
import ServicesIcon from "assets/images/navbar/Услуги.png";
import NewsIcon from "assets/images/navbar/Новости.png";
import ContactUsIcon from "assets/images/navbar/Связаться.png";
import EmployeeIcon from "assets/images/navbar/Сотрудники.png";

// Pages
import Services from "app/services/page";
import InfoPage from "app/info/page";
import ContactUs from "app/contact-us/page";
import SignIn from "app/sign-in/page";
import AboutUs from "app/about-us/page";
import Gallery from "app/gallery/page";
import Employee from "app/employee";
import SignUp from "app/signup/Register";
import NewsPage from "app/news/NewsPage";
import { isUserAuthenticated } from "./auth";
import SignOutButton from "components/Navbars/DefaultNavbar/SignOut";

const iconStyle = {
  width: "15px", // Укажите желаемую ширину
  height: "15px", // Укажите желаемую высоту
};

const isAuth = isUserAuthenticated();
const routes = [
  {
    name: "О нас",
    icon: <img src={AboutUsIcon} style={iconStyle} />,
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "Информация",
    icon: <img src={InformationIcon} style={iconStyle} />,
    route: "/info",
    component: <InfoPage />,
  },
  {
    name: "Услуги",
    icon: <img src={ServicesIcon} style={iconStyle} />,
    route: "/services",
    component: <Services />,
  },
  {
    name: "Галерея",
    icon: <img src={GalleryIcon} style={iconStyle} />,
    route: "/gallery",
    component: <Gallery />,
  },
  {
    icon: <img src={NewsIcon} style={iconStyle} />,
    name: "Новости",
    route: "/news",
    component: <NewsPage />,
  },
  {
    name: "Связаться",
    icon: <img src={ContactUsIcon} style={iconStyle} />,
    route: "/contact-us",
    component: <ContactUs />,
  },
  {
    name: "Сотрудники",
    icon: <img src={EmployeeIcon} style={iconStyle} />,
    route: "/employee",
    component: <Employee />,
  },
  isAuth
  ? {
      type: "button",
      name: "Выйти",
      route: "/sign-out",
      component: <SignOutButton />,
    }
  : {
      type: "external",
      name: "Войти",
      route: "/sign-in",
      label: "Войти",
      color: "info",
      component: <SignIn />,
    },
];

export default routes;
