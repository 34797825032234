import React from "react";
import PropTypes from "prop-types";

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {  // Adjusted condition to allow 0 (first page)
      onPageChange(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 0; i < totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          style={{
            padding: "5px 10px",
            margin: "0 5px",
            backgroundColor: currentPage === i ? "#1976d2" : "#fff",
            color: currentPage === i ? "#fff" : "#000",
            border: "1px solid #1976d2",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          {i + 1}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <button
        onClick={() => handlePageClick(currentPage - 1)} // Decrease page number
        disabled={currentPage === 0}  // Disable on first page
        style={{
          padding: "5px 10px",
          margin: "0 5px",
          backgroundColor: "#1976d2",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: currentPage === 0 ? "not-allowed" : "pointer", // Cursor style on first page
        }}
      >
        Previous
      </button>
      {renderPageNumbers()}
      <button
        onClick={() => handlePageClick(currentPage + 1)} // Increase page number
        disabled={currentPage === totalPages - 1} // Disable on last page
        style={{
          padding: "5px 10px",
          margin: "0 5px",
          backgroundColor: "#1976d2",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: currentPage === totalPages - 1 ? "not-allowed" : "pointer", // Cursor style on last page
        }}
      >
        Next
      </button>
    </div>
  );
};

CustomPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default CustomPagination;
