import axios from 'axios';

import { BASE_URL } from './api';
const accessToken = localStorage.getItem("accessToken");

if (!accessToken) {
  console.error("No access token available.");
}

const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': accessToken ? `Bearer ${accessToken}` : '',
  },
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Если ошибка связана с истекшим токеном (статус 440 или 403)
    if (error.response.status === 440 || (error.response.status === 403 && !originalRequest._retry)) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem('token'); // Получаем refresh token

      if (refreshToken) {
        try {
          const response = await axios.post(`${BASE_URL}/api/auth/refresh-token`, { token: refreshToken });

          const newAccessToken = response.data.accessToken;
          const newRefreshToken = response.data.token; 

          // Сохраняем новые токены
          localStorage.setItem('accessToken', newAccessToken);
          localStorage.setItem('token', newRefreshToken);

          console.log('New tokens obtained. AccessToken:', newAccessToken);

          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

          return axios(originalRequest); 
        } catch (refreshError) {
          console.error('Failed to refresh token:', refreshError);

          if (refreshError.response && (refreshError.response.status === 404 || refreshError.response.status === 423)) {
            window.location.href = '/sign-in';
            console.error("Token not found or expired. Redirecting to login...");
          }

          return Promise.reject(refreshError);
        }
      } else {
        // window.location.href = '/sign-in';
        console.error('No refresh token available.');
      }
    }

    // Если ошибка не связана с истекшим токеном или повторная попытка не удалась, отклоняем промис с ошибкой
    return Promise.reject(error);
  }
);

export default axiosClient;
