import  { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function AutoSignOut() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    console.log(localStorage.getItem("token"));
    // Redirect the user to the homepage
    navigate("/");
  }, [navigate]);

  return null; 
}

export default AutoSignOut;
